
<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('research_manage.committee_user_setup') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
        <b-col lg="6" sm="12">
          <ValidationProvider name="Proposal Invitation Circular" vid="proposal_invitation_circular" rules="required|min_value:1">
              <b-form-group
                  class="row"
                  label-cols-sm="6"
                  label-for="proposal_invitation_circular"
                  slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                  {{ $t('research_manage.proposal_invitation_circular')}} <span class="text-danger">*</span>
                  </template>
                  <b-form-select
                  plain
                  v-model="search.proposal_invitation_circular"
                  :options="proposalListList"
                  id="organization_name"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                  {{ errors[0] }}
                  </div>
              </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col lg="10" sm="12">
        </b-col>
        <b-col lg="2" md="2" sm="12" xs="12">
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
        </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('research_manage.committee_user_info') + ' ' + $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="bg-primary"  bordered hover :items="datas" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(lab_no)="data">
                      <span class="capitalize">{{ data.item.lab_no }}</span>
                    </template>
                    <template v-slot:cell(org)="data">
                      <span class="capitalize">{{  orgList.find(e=>e.value===data.item.org_id).text }}</span>
                    </template>
                    <template v-slot:cell(project)="data">
                      <span class="capitalize">{{ projectList.find(e=>e.value===data.item.project_info_id) ?  projectList.find(e=>e.value===data.item.project_info_id).text : 'N / A'}}</span>
                    </template>
                    <template v-slot:cell(sector)="data">
                      <span class="capitalize">{{ sectorList.find(e=>e.value===data.item.sector_id) ? sectorList.find(e=>e.value===data.item.sector_id).text : 'N / A' }}</span>
                    </template>
                    <template v-slot:cell(sub_sector_name)="data">
                      {{ data.item.sub_sector_name }}
                    </template>
                    <template v-slot:cell(sub_sector_name_bn)="data">
                      {{ data.item.sub_sector_name_bn }}
                    </template>
                    <template v-slot:cell(status)="data">
                      <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                      <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button title="Edit" v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="editt(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button :variant="data.item.status === 1 ? ' iq-bg-success border' : '  iq-bg-danger border'" size="sm" @click="remove(data.item)">
                        <i class="fas" :class="data.item.status ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
                      </b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :proposalList="proposalData" :committeeListNew="committeeListNew" :committeememberdetails="committeeMemberDetails" :roledata="RoleData" :roles="Roles" :committeeData="committeeTypeList" :committeeList="committeeList" :key="editItemId"/>
      </p>
    </b-modal>
    <b-modal id="modal-detail" size="lg" :title="$t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <DetailModal :item="itemDetail"/>
    </b-modal>
  </b-container>
</template>
<script>
import FormV from './Form.vue'
import DetailModal from './DetailModal.vue'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { CommitteeUserSetupInfoList, CommitteeUserSetupInfoToggleStatus, proposalInvitationList, CommitteeTypeSetupInfoList } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'
import { core } from '@/config/pluginInit'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    FormV, DetailModal
  },
  data () {
    return {
        itemDetail: {},
        datas: [],
        RoleData: [],
        committeeTypeList: [],
        Roles: [],
        committeeTypeData: [],
        proposalData: [],
        proposalListList: [],
        committeeList: [],
        committeeMemberDetails: [],
        committeeListNew: [],
        search: {
            org: '',
            proposal_invitation_circular: '0'
        },
        sectorByProjectList: []
    }
  },
  watch: {
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
      ...mapGetters({
          authUser: 'Auth/authUser'
      }),
      projectList: function () {
          return this.$store.state.AgriResearch.commonObj.projectList.map(project => {
                  return { value: project.value, text: this.$i18n.locale === 'en' ? project.text_en : project.text_bn }
              })
      },
      sectorList: function () {
        return this.$store.state.AgriResearch.commonObj.sectorList
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('research_manage.committee_setup_info_entry') : this.$t('research_manage.committee_setup_info_modify')
      },
      columns () {
          let labels = []
          if (this.$i18n.locale === 'bn') {
             labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
              { label: this.$t('research_manage.proposal_invitation_circular'), class: 'text-left' },
              { label: this.$t('research_manage.research_program'), class: 'text-left' },
              { label: this.$t('research_manage.committee_type'), class: 'text-left' },
              { label: this.$t('research_manage.committee_user_setup_name'), class: 'text-left' },
              { label: this.$t('globalTrans.status'), class: 'text-center' },
              { label: this.$t('globalTrans.action'), class: 'text-center' }
          ]
          } else {
             labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
              { label: this.$t('research_manage.proposal_invitation_circular'), class: 'text-left' },
              { label: this.$t('research_manage.research_program'), class: 'text-left' },
              { label: this.$t('research_manage.committee_type'), class: 'text-left' },
              { label: this.$t('research_manage.committee_user_setup_name'), class: 'text-left' },
              { label: this.$t('globalTrans.status'), class: 'text-center' },
              { label: this.$t('globalTrans.action'), class: 'text-center' }
          ]
          }

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
                  { key: 'index' },
                  { key: 'Proposallist_bn' },
                  { key: 'research_program' },
                  { key: 'committeetypelist_bn' },
                  { key: 'committee_setup_name_bn' },
                  { key: 'status' },
                  { key: 'action' }
              ]
          } else {
              keys = [
                  { key: 'index' },
                  { key: 'Proposallist' },
                  { key: 'research_program' },
                  { key: 'committeetypelist' },
                  { key: 'committee_setup_name_en' },
                  { key: 'status' },
                  { key: 'action' }
              ]
          }
          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      }
  },
  mounted () {
    core.index()
    if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search)
    }
    this.loadData()
    this.loadCommitteeTypeData()
    this.loadRoleData()
  },
  methods: {
    editt (data) {
      this.editItemId = data.id
     this.committeeListNew = this.committeeMemberDetails.filter(item => parseInt(item.committee_user_setup_id) === data.id)
    },
    details (item) {
      this.itemDetail = item
    },
    async searchData () {
      this.loadData()
    },
    getSectorList () {
        const sectorList = this.$store.state.AgriResearch.commonObj.sectorList.filter(el => el.status === 0 && el.project_id === this.search.project_info_id)
        this.sectorByProjectList = sectorList.map(subProject => {
            return { value: subProject.value, text: this.$i18n.locale === 'en' ? subProject.text_en : subProject.text_bn }
          })
    },
    getResearchType (data) {
       return data.map(d => {
          return this.options.find((e) => e.value === parseInt(d)).text
        }).toString()
    },
    remove (item) {
      this.changeStatus(agriResearchServiceBaseUrl, CommitteeUserSetupInfoToggleStatus, item)
    },
    loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(agriResearchServiceBaseUrl, CommitteeUserSetupInfoList, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', response.data.data)
            this.committeeMemberDetails = response.committeeMemberDetails
            this.Roles = response.roles
            this.committeeTypeList = response.committeeTypeList
            this.proposalData = response.ProposalInvitation
            this.proposalListList = response.ProposalInvitation
            const state = this.$store.state
            if (this.$i18n.locale === 'bn') {
            this.proposalListList.map(function (element) {
              const value = state.AgriResearch.commonObj.thematicAreaList.find(item => item.value === element.thematic_area_id)
                element.value = value.value
                element.text = value.text_bn
              })
            } else {
                this.proposalListList.map(function (element) {
              const value = state.AgriResearch.commonObj.thematicAreaList.find(item => item.value === element.thematic_area_id)
                element.value = value.value
                element.text = value.text_en
              })
            }
            this.committeeList = response.committeeList
            this.paginationData(response.data)
            const allData = this.$store.state.list
            this.datas = JSON.parse(JSON.stringify(allData))
            this.datas.map(function (element) {
            try {
             element.orglist = state.commonObj.organizationProfileList.find(item => item.value === parseInt(element.organization_name)).text_en
            } catch (e) {
            }
            })
            this.datas.map(function (element) {
            const value = state.AgriResearch.commonObj.thematicAreaList.find(item => item.value === parseInt(element.proposal_invitation_circular))
            try {
              element.Proposallist = value.text_en
              element.Proposallist_bn = value.text_bn
            } catch (e) {
            }
            })
            const vm = this
            this.datas.map(function (element) {
            const value = vm.committeeTypeList.find(item => item.id === parseInt(element.committee_type))
            try {
            element.committeetypelist = value.committee_type_name_en
            element.committeetypelist_bn = value.committee_type_name_bn
            } catch (e) {
            }
            })
            this.datas.map(function (element) {
            const value = vm.committeeList.find(item => item.id === parseInt(element.committee_user_setup_name))
            try {
            element.committee_setup_name_en = value.committee_setup_name_en
            element.committee_setup_name_bn = value.committee_setup_name_bn
            } catch (e) {
            }
            })
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    loadProposalData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        RestApi.getData(agriResearchServiceBaseUrl, proposalInvitationList, params).then(response => {
          if (response.success) {
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
    },
    loadCommitteeTypeData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      RestApi.getData(agriResearchServiceBaseUrl, CommitteeTypeSetupInfoList, params).then(response => {
          if (response.success) {
            this.committeeTypeData = response.data.data
          }
      })
    },
    dataList (data) {
      const listData = data
      return listData
    }
  }
}
</script>
