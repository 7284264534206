<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                    <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Proposal Invitation Circular" vid="proposal_invitation_circular" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="proposal_invitation_circular"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('research_manage.proposal_invitation_circular')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="committee_user_setup_extra.proposal_invitation_circular"
                                :options="proposalList"
                                id="organization_name"
                                @change="setResearchProgram(committee_user_setup_extra.proposal_invitation_circular)"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Research Program" vid="research_program" rules="">
                          <b-form-group
                              class="row"
                              label-cols-sm="5"
                              label-for="research_program"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('research_manage.research_program')}}
                              </template>
                              <b-form-input
                              id="research_program"
                              v-model="committee_user_setup_extra.research_program"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Committee Type" vid="committee_type" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="committee_type"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('research_manage.committee_type')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="committee_user_setup_extra.committee_type"
                                :options="committeeData"
                                @change="setCommitteeName(committee_user_setup_extra.committee_type)"
                                id="organization_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Committee User Setup Name" vid="committee_user_setup_name" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="5"
                                label-for="committee_user_setup_name"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('research_manage.committee_user_setup_name')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="committee_user_setup_extra.committee_user_setup_name"
                                @change="committeeUserSetupNameChange()"
                                :options="committeeListDrop"
                                id="committee_user_setup_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row class="bg-success text-white text-center mb-3">
                        <h4 class="text-center w-50 m-auto">{{$t('research_manage.committee_member_details')}}</h4>
                    </b-row>
                    <ul>
                    <b-row v-for="(detail,index) in committee_user_setup.committee_member_details" :key="index" class="text-black mb-3">
                                <b-col lg="6" sm="12">
                                  <ValidationProvider name="Organization Name" vid="organization_name" rules="required|min_value:1">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="5"
                                          label-for="organization_name"
                                          slot-scope="{ valid, errors }"
                                          >
                                          <template v-slot:label>
                                          {{ $t('research_manage.organization_name')}} <span class="text-danger">*</span>
                                          </template>
                                          <b-form-select
                                          plain
                                          v-model="detail.org_id"
                                          @change="setDesignation(detail.org_id,index)"
                                          :options="orgList"
                                          id="organization_name"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          >
                                          <template v-slot:first>
                                              <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                          </b-form-select>
                                          <div class="invalid-feedback">
                                          {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Designation" vid="designation" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="5"
                                        label-for="designation"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                        {{$t('research_manage.designation')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                        plain
                                        v-model="detail.designation"
                                        :options="detail.designationList"
                                        @change="desig(detail.designation,index)"
                                        id="designation"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Employee Name" vid="employee_name" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="5"
                                        label-for="employee_name"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('research_manage.employee_name')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                        plain
                                        v-model="detail.employee_name"
                                        :options="detail.test"
                                        id="employee_name"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Role Name" vid="role_name" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="5"
                                        label-for="role_name"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('research_manage.role_name')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                        plain
                                        v-model="detail.role_name"
                                        :options="roles"
                                        id="employee_name"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xl="6" lg="6" sm="6">
                                <button @click="remove(index)" class="btn btn-sm btn-danger float-right" type="button" v-show="index || ( !index && committee_user_setup.committee_member_details.length > 1)"><i class="fas fa-window-close m-0"></i> {{ $t('globalTrans.delete') }}</button>
                                </b-col>
                                <b-col xl="6" lg="6" sm="6">
                                </b-col>
                                <b-col xl="6" lg="6" sm="6">
                                </b-col>
                                <b-col xl="6" lg="6" sm="6">
                                  <button @click="addItem" class="btn btn-sm btn-primary mr-2 float-right mt-4" type="button" v-show="index == committee_user_setup.committee_member_details.length-1"><i class="fas fa-plus-circle m-0"></i> {{$t('globalTrans.add_more')}}</button>
                                </b-col>
                        </b-row>
                    </ul>
                    <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                    </div>
                </b-form>
              </ValidationObserver>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl, authServiceBaseUrl } from '../../../../../../config/api_config'
import { CommitteeUserSetupInfoStore, CommitteeUserSetupInfoUpdate } from '../../../api/routes'
import researchTestingModal from '@/mixins/research-testing-modal'
export default {
  name: 'FormLayout',
  props: ['id', 'proposalList', 'committeeData', 'roledata', 'roles', 'committeeList', 'committeememberdetails', 'committeeListNew'],
  mixins: [researchTestingModal],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
        org: [],
        DesignationList: [],
        newTest:
          {
            testone: []
          },
        users: [],
        committee_member_details_extra: [
          {
            designation: '',
            employee_name: '',
            role_name: '',
            test: []
          }
        ],
        committeeTypeData: [],
        committeeTypeList: [],
        committeeListDrop: [],
        search: {
         designation_id: 0
        },
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        committee_user_setup: {
          proposal_invitation_circular: '',
          research_program: '',
          committee_type: '',
          committee_user_setup_name: '',
          committee_member_details: [
          {
            org_id: '',
            designation: '',
            employee_name: '',
            role_name: '',
            designationList: [],
            test: []
          }
          ]
        },
        committee_user_setup_extra: {
        org_id: '',
        proposal_invitation_circular: '',
        research_program: '',
        committee_type: '',
        committee_user_setup_name: '',
        committee_member_details: [
        {
          designation: '',
          employee_name: '',
          role_name: '',
          test: []
        }
        ]
      }
    }
  },
  computed: {
    sectorList: function () {
        return this.$store.state.AgriResearch.commonObj.sectorList
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    }
  },
  created () {
        const state = this.$store.state
        if (this.$i18n.locale === 'bn') {
        this.proposalList.map(function (element) {
          const value = state.AgriResearch.commonObj.thematicAreaList.find(item => item.value === element.thematic_area_id)
            element.value = value.value
            element.text = value.text_bn
          })
        } else {
            this.proposalList.map(function (element) {
          const value = state.AgriResearch.commonObj.thematicAreaList.find(item => item.value === element.thematic_area_id)
            element.value = value.value
            element.text = value.text_en
          })
        }
          this.committeeTypeData = this.committeeData
          if (this.$i18n.locale === 'bn') {
            this.committeeData.map(function (element) {
              element.value = element.id
              element.text = element.committee_type_name_bn
            })
          } else {
            this.committeeData.map(function (element) {
              element.value = element.id
              element.text = element.committee_type_name_en
            })
          }
          if (this.$i18n.locale === 'bn') {
            this.roles.map(function (element) {
              element.value = element.id
              element.text = element.role_name_bn
            })
          } else {
            this.roles.map(function (element) {
              element.value = element.id
              element.text = element.role_name_en
            })
          }
        if (this.id) {
        const tmp = this.getSubSectorData()
        // Object.freeze(tmp)
        this.committee_user_setup_extra = tmp
        this.DesignationList = this.$store.state.commonObj.designationList.filter(item => item.org_id === parseInt(this.committee_user_setup.org_id))
        this.newTest.testone.push(this.DesignationList)
        this.setCommitteeNametwo(this.committee_user_setup_extra.committee_type)
        try {
        // this.desigtwo(this.committee_user_setup_extra.committee_member_details.designation)
        this.committee_user_setup_extra.role = this.roledata.filter(element => element.id === this.id)
        } catch (e) {}
       }
      if (this.committeeListNew.length > 0) {
      this.committee_user_setup.committee_member_details = []
      this.committee_user_setup.committee_member_details.push(...this.committeeListNew)
      this.committee_user_setup.committee_member_details.map(function (element, index) {
      element.designationList = []
      element.designationList = this.$store.state.commonObj.designationList.filter(item => item.org_id === parseInt(element.org_id))
        })
      } else {
      this.committee_user_setup.committee_member_details = []
      this.addItem()
      }
    this.org = [
      { value: '2021', text: '2021' },
      { value: '2020', text: '2020' },
      { value: '2019', text: '2019' },
      { value: '2018', text: '2018' },
      { value: '2017', text: '2017' },
      { value: '2016', text: '2016' },
      { value: '2015', text: '2015' },
      { value: '2014', text: '2014' },
      { value: '2013', text: '2013' },
      { value: '2012', text: '2012' },
      { value: '2011', text: '2011' },
      { value: '2010', text: '2010' },
      { value: '2009', text: '2009' },
      { value: '2008', text: '2008' },
      { value: '2007', text: '2007' },
      { value: '2006', text: '2006' },
      { value: '2005', text: '2005' },
      { value: '2004', text: '2004' },
      { value: '2003', text: '2003' },
      { value: '2001', text: '2001' },
      { value: '2000', text: '2000' },
      { value: '1999', text: '1999' },
      { value: '1998', text: '1998' },
      { value: '1997', text: '1997' },
      { value: '1996', text: '1996' },
      { value: '1995', text: '1995' },
      { value: '1994', text: '1994' },
      { value: '1993', text: '1993' },
      { value: '1992', text: '1992' },
      { value: '1991', text: '1991' },
      { value: '1990', text: '1990' },
      { value: '1989', text: '1989' },
      { value: '1988', text: '1988' },
      { value: '1987', text: '1987' },
      { value: '1986', text: '1986' },
      { value: '1985', text: '1985' },
      { value: '1984', text: '1984' },
      { value: '1983', text: '1983' },
      { value: '1982', text: '1982' },
      { value: '1981', text: '1981' },
      { value: '1980', text: '1980' },
      { value: '1979', text: '1979' },
      { value: '1978', text: '1978' },
      { value: '1977', text: '1977' },
      { value: '1976', text: '1976' },
      { value: '1975', text: '1975' },
      { value: '1974', text: '1974' },
      { value: '1973', text: '1973' },
      { value: '1972', text: '1972' },
      { value: '1971', text: '1971' },
      { value: '1970', text: '1970' },
      { value: '1969', text: '1969' }
      ]
    this.month = [
      { value: 'january', text: 'January' },
      { value: 'february', text: 'February' },
      { value: 'march', text: 'March' },
      { value: 'april', text: 'April' },
      { value: 'may', text: 'May' },
      { value: 'june', text: 'June' },
      { value: 'july', text: 'July' },
      { value: 'august', text: 'August' },
      { value: 'september', text: 'September' },
      { value: 'october', text: 'October' },
      { value: 'novmber', text: 'Novmber' },
      { value: 'december', text: 'December' }
      ]
  },
  mounted () {
    core.index()
     if (this.id) {
        this.updateLoop()
        try {
        try {
        this.committee_user_setup.committee_member_details.map(function (element) {
           element.test = JSON.parse(element.testone)
           element.designationList = JSON.parse(element.designationListDB)
        })
        } catch (e) {
        }
        } catch (e) {
        }
     }
  },
  methods: {
    committeeUserSetupNameChange (a, event) {
    },
    updateLoop () {
           const education = this.committeememberdetails.filter(item => parseInt(item.committee_user_setup_id) === this.id)
         if (education.length > 0) {
         this.committee_user_setup.committee_member_details = []
         this.committee_user_setup.committee_member_details.push(...education)
         } else {
         this.committee_user_setup.committee_member_details = []
         this.addItem()
         }
    },
    setDesignation (orgid, index) {
      this.committee_user_setup.committee_member_details[index].designationList = []
      this.committee_user_setup.committee_member_details[index].designationList = this.$store.state.commonObj.designationList.filter(item => item.org_id === parseInt(orgid))
      // this.DesignationList = this.$store.state.commonObj.designationList.filter(item => item.org_id === parseInt(orgid))
    },
    setDesignationtwo (orgid, index) {
      alert('hello')
      try {
      this.committee_user_setup.committee_user_setup_name[index].designationList = []
      this.committee_user_setup.committee_user_setup_name[index].designationList = this.$store.state.commonObj.designationList.filter(item => item.org_id === parseInt(orgid))
      } catch (e) {
      }
    },
    setCommitteeName (commiteeTypeId) {
       const commiiteeType = this.committeeTypeData.find(item => item.id === commiteeTypeId)
       this.committeeListDrop = this.committeeList.filter(item => item.committee_type === commiiteeType.committee_type_name_en)
      if (this.$i18n.locale === 'bn') {
      this.committeeListDrop.map(function (element) {
        element.value = element.id
        element.text = element.committee_setup_name_bn
      })
    } else {
      this.committeeListDrop.map(function (element) {
        element.value = element.id
        element.text = element.committee_setup_name_en
      })
    }
    },
    setCommitteeNametwo (commiteeTypeId) {
       const commiiteeType = this.committeeTypeData.find(item => item.id === parseInt(commiteeTypeId))
       this.committeeListDrop = this.committeeList.filter(item => item.committee_type === commiiteeType.committee_type_name_en)
      if (this.$i18n.locale === 'bn') {
      this.committeeListDrop.map(function (element) {
        element.value = element.id
        element.text = element.committee_setup_name_bn
      })
    } else {
      this.committeeListDrop.map(function (element) {
        element.value = element.id
        element.text = element.committee_setup_name_en
      })
    }
    },
    desig (desid, index) {
        this.search.designation_id = desid
        RestApi.getData(authServiceBaseUrl, 'user/list-by-designation', this.search).then(response => {
        if (this.$i18n.locale === 'bn') {
          this.users = []
            let elem = 0
            response.data.map((element) => {
            elem = element.id
            this.users.push({ value: elem, text: element.name_bn })
            })
          this.committee_user_setup.committee_member_details[index].test = this.users
        } else {
          this.users = []
          let elem = 0
          response.data.map((element) => {
          elem = element.id
          this.users.push({ value: elem, text: element.name })
          })
        this.committee_user_setup.committee_member_details[index].test = this.users
        }
        }, error => {
          if (error) {
            //
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
       },
       desigtwo (desid) {
        this.search.designation_id = desid
        RestApi.getData(authServiceBaseUrl, 'user/list-by-designation', this.search).then(response => {
        if (this.$i18n.locale === 'bn') {
          this.users = response.data.map(function (element) {
          element.value = element.id
          element.text = element.name_bn
        })
        } else {
          let elem = 0
          response.data.map((element) => {
          elem = element.id
          this.users.push({ value: elem, text: name })
          })
        }
        }, error => {
          if (error) {
            //
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
      },
    setResearchProgram (id) {
    const proposal = this.proposalList.find(element => element.invitation_code === id)
    this.committee_user_setup.research_program = proposal.research_objectives
    },
    remove (key) {
        this.committee_user_setup.committee_member_details.splice(key, 1)
    },
    addItem () {
      const obj = {
        designation: '',
        employee_name: '',
        role_name: '',
        test: []
      }
      // const key1 = parseInt(this.committee_user_setup.role.length - 1)
      // const item = this.formData.unit_list_details[key1]
      this.committee_user_setup.committee_member_details.push(obj)
    },
    getSubSectorData () {
      const tmpData = this.$store.state.list.find(project => project.id === this.id)
      return tmpData
    },
    async saveData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.id) {
        this.committee_user_setup_extra.committee_member_details = []
        this.committee_user_setup_extra.committee_member_details = this.committee_user_setup.committee_member_details
        result = await RestApi.putData(agriResearchServiceBaseUrl, `${CommitteeUserSetupInfoUpdate}/${this.id}`, this.committee_user_setup_extra)
      } else {
        this.committee_user_setup_extra.committee_member_details = []
        this.committee_user_setup_extra.committee_member_details = this.committee_user_setup.committee_member_details
        result = await RestApi.postData(agriResearchServiceBaseUrl, CommitteeUserSetupInfoStore, this.committee_user_setup_extra)
      }
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)
      if (result.success) {
        this.$store.dispatch('AgriResearch/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    }
  }
}
</script>
